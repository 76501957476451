<template>
	<div class="modal modal--application modal-application">
		<div class="modal__loading" v-if="loading">
			<loading-spinner />
		</div>
		<div class="modal-application__form">
			<form-application :application="application" />
		</div>
		<div class="modal-application__stats">
			<dl>
				<dt>Date Created</dt>
				<dd>{{ application.dateCreated | date('PPp') }}</dd>
				<dt>Created By</dt>
				<dd>{{ application.createdByName }}</dd>
				<dt>Date Modified</dt>
				<dd>{{ application.dateModified | date('PPp') }}</dd>
				<dt>Modified By</dt>
				<dd>{{ application.modifiedByName }}</dd>
			</dl>
			<h3>Security</h3>
			<dl>
				<dt>Client ID</dt>
				<dd>{{ application.appId }}</dd>
				<dt>Client Secret</dt>
				<dd>
					{{ clientSecret || '******'}}<br />

					<a href="#" class="a" @click="generateKey" v-if="!clientSecret">generate</a>
				</dd>
			</dl>
		</div>
	</div>
</template>

<script>
import FormApplication from '@/components/FormApplication'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'ModalApplication',
	components: {
		FormApplication,
		LoadingSpinner,
	},
	props: {
		listener: Function,
	},
	data: () => ({
		clientSecret: null,
		loading: false,
	}),
	async mounted() {
		this.loading = true
		if (this.$route.params.appId) {
			await this.$store.dispatch('application/get', this.$route.params.appId)
		}
		this.loading = false
	},
	destroyed() {
		this.$store.dispatch('application/unset')
	},
	computed: {
		application() {
			return this.$store.getters['application/application']
		},
	},
	methods: {
		generateKey() {
			this.$confirm(`THIS WILL REPLACE THE EXISTING CLIENT SECRET. \n\nCredentials will need to be updated for all instances of this access. \n\nTHIS IS NOT REVERSIBLE.`,
			() => {
				this.$store.dispatch('application/generateSecret', this.application.appId)
					.then((res) => {
						this.clientSecret = res

						this.$confirm(`THIS WILL ONLY BE DISPLAYED ONCE. Save this Client Secret where needed: \n\n${res}`,
						() => {},
						{
							showCancel: false,
						})
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
			},
			{
				title: `Generate New Client Secret?`,
				acceptLabel: `Yes, I Know What I Am Doing`,
			})
		},
	},
}
</script>

<style scoped lang="scss">
.modal-application {
	display: grid;
	grid-template-columns: 2fr minmax(10rem, 1fr);
	min-width: 70vw;
	width: 100%;

	@include modules.media-query-max-width('desktop-xs') {
		grid-template-columns: 1fr;
	}

	dl {
		@include modules.fontSize(14px);
		@include modules.gutter('grid-row-gap', 0.5);
		display: grid;
		grid-column-gap: 0;
		grid-template-columns: 1fr auto;
	}

	dd {
		margin: 0;
		text-align: right;
		padding: 0.5rem;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}

	dt {
		font-weight: 500;
		padding: 0.5rem;
		text-transform: uppercase;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}
}
</style>
