<template>
	<div class="partial partial--applications applications">
		<div class="partial__heading">
			<ion-icon name="business-outline" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">Manage Resources</h1>

			<div class="partial__actions">
				<router-link :to="{name: 'application' }" class="button">
					<div class="mdc-button__ripple"></div>
					<ion-icon name="add-circle-outline" class="mdc-button__icon" aria-hidden="true"></ion-icon>
					<span class="mdc-button__label">Add Resource</span>
				</router-link>
			</div>
		</div>

		<div class="partial__body applications__body">
			<list-applications :applications="applications" :loading="loading" />
		</div>

		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import Modal from '@/components/Modal'
import ListApplications from '@/components/ListApplications'

export default {
	name: 'PartialApplications',
	components: {
		ListApplications,
		Modal,
	},
	metaInfo: () => ({
		title: 'Resources',
	}),
	async mounted() {
		await this.$store.dispatch('application/list')
	},
	computed: {
		applications() {
			return this.$store.getters['application/applications']
		},
		loading() {
			return this.$store.getters['misc/loading']
		},
		modalVisible() {
			return this.$route.name == 'application'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
	},
	methods: {
		hideModal(clicked) {
			this.$router.replace({ name: 'applications' })
		},
	},
}
</script>

<style scoped lang="scss">

</style>
