<template>
	<form class="form form--application form-application" @submit.prevent="submitForm(fields)">
		<div class="form__loader" v-if="loading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Name" v-model="fields.name" :required="true" autocomplete="nope" />
				<text-field class="form__field" type="url" label="Website" v-model="fields.url" :required="true" />
				<select-field class="form__field" label="Status" v-model="fields.status" :options="applicationStatus" />
			</div>

			<div class="form-row">
				<text-area class="form__field" label="Description" v-model="fields.description" />
			</div>

			<h2 class="form-section__title">Settings</h2>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Contact Email" v-model="fields.settings.contactEmail" />
			</div>

			<div class="form__actions form__actions--horizontal">
				<a-button class="button--primary form__action" type="submit" :disabled="loading">
					{{ fields.appId ? `Update` : `Create` }} Resource
				</a-button>
			</div>
		</div>
	</form>
</template>

<script>
import AButton from '@/components/AButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import TextArea from '@/components/TextArea'
import { ApplicationStatus } from '@/lib/enums'
import { get, pick } from 'lodash'

export default {
	name: 'FormApplication',
	components: {
		AButton,
		LoadingSpinner,
		SelectField,
		TextField,
		TextArea
	},
	props: {
		loading: Boolean,
		application: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			settings: {},
		},
		fields: {},
		isLoading: false,
	}),
	computed: {
		applicationStatus() {
			let status = []
			for (let key in ApplicationStatus) {
				status.push({
					value: key,
					label: ApplicationStatus[key],
				})
			}

			return status
		},
	},
	methods: {
		submitForm(fields) {
			if (fields.appId) {
				this.isLoading = true
				this.$store.dispatch('application/update', { appId: fields.appId, data: fields })
					.then(() => {
						this.$notice(`Application updated!`)
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})
			} else {
				this.isLoading = true
				this.$store.dispatch('application/create', fields)
					.then(() => {
						this.$notice(`Application created!`)
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})
			}
		},
	},
	watch: {
		application: {
			immediate: true,
			handler: function (appProp) {
				this.fields = {
					...this.defaults,
					...appProp,
					...pick(appProp, ['appId', 'createdBy', 'createdByName', 'dateCreated', 'dateModified', 'modifiedBy', 'modifiedByName', 'name', 'settings', 'status', 'url', 'description',]),
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">
.form-application {
}
</style>
